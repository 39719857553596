<template>
  <div class="consumeRecord">
    <cell-group title="消费统计">
      <cell title="选择时间" :value="startTime" is-link arrow-direction="down" @click="openTime(1)"></cell>
      <!-- <cell title="结束时间" :value="endTime" is-link arrow-direction="down" @click="openTime(2)"></cell> -->
      <!-- <cell title="消费总额(元)" value="内容">
        <template slot="default">
          <span style="color:red">¥0.00</span>
        </template>
      </cell> -->
      
    </cell-group>
    <Divider>记录详情</Divider>
    <list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <cell v-for="(item, index) in recordList" :key="index" :title="item.text" :label="item.create_time" >
          <template slot="default">
            {{item.type==1?"+":"-"}}{{item.amount}}
          </template>
        </cell>
      </list>
    <popup v-model="show" position="bottom">
      <datetime-picker
        v-model="currentDate"
        type="year-month"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="show = false"
        @confirm="selectTime"
      />
    </popup>
  </div>
</template>


<script>
import Creditshop from "@/api/creditshop";
import { Cell, CellGroup, DatetimePicker, Popup, List,Divider  } from "vant";
export default {
  components: {
    Cell,
    CellGroup,
    DatetimePicker,
    Popup,
    List,
    Divider 
  },
  data() {
    return {
      show: false,
      minDate: new Date('2020/01/01'),
      maxDate: new Date('2025/01/01'),
      currentDate: new Date(),
      loading: false,
      finished: false,
      timeType: 1,
      page: 0,
      recordList: [],
      startTime: "",
      endTime: ""
    };
  },
  methods: {
    setTime(fmt) {
      const Y = fmt.getFullYear();
      const M = (fmt.getMonth() + 1).toString().padStart(2, "0");
      return `${Y}-${M}`;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    selectTime(val) {
      console.log(val);
      if (this.timeType == 1) {
        this.startTime = this.setTime(val)
      } else {
        this.endTime = this.setTime(val)
      }
      this.show = false;
      this.page = 0;
      this.recordList = [];
      this.onLoad();
    },
    openTime(type) {
      this.timeType = type;
      this.show = true;
    },
    async onLoad() {
      this.page++;
      try {
        const res = await Creditshop.team_consume_record(
          this.$route.query.id,
          this.startTime,
          this.endTime,
          this.page
        );

        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          this.recordList.push(element);
        }
        this.loading = false;
        if (res.data.length == 0) {
          this.finished = true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.consumeRecord {
  height: 100vh;
}
</style>
<style lang="scss">
.consumeRecord {
  .van-list__finished-text {
    width: 375px;
  }
}
</style>